import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
} from '@angular/fire/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    ...canActivate(redirectLoggedInToHome),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'home-paciente/:pacienteuid',
    loadChildren: () => import('./paciente/home-paciente/home-paciente.module').then( m => m.HomePacientePageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'fotografias/:pacienteuid',
    loadChildren: () => import('./paciente/fotografias/fotografias.module').then( m => m.FotografiasPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'informacion/:pacienteuid',
    loadChildren: () => import('./paciente/informacion/informacion.module').then( m => m.InformacionPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'musica/:pacienteuid',
    loadChildren: () => import('./paciente/musica/musica.module').then( m => m.MusicaPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'comunicacion/:uidPaciente/:uidComunicacion',
    loadChildren: () => import('./paciente/comunicacion/comunicacion.module').then( m => m.ComunicacionPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'video/:pacienteuid',
    loadChildren: () => import('./paciente/video/video.module').then( m => m.VideoPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'mensaje/:pacienteuid',
    loadChildren: () => import('./paciente/mensaje/mensaje.module').then( m => m.MensajePageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'pantalla-espera-actividad/:pacienteuid',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./paciente/pantalla-espera-actividad/pantalla-espera-actividad.module').then( m => m.PantallaEsperaActividadPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'musica-relajacion/:pacienteuid',
    loadChildren: () => import('./paciente/musica-relajacion/musica-relajacion.module').then( m => m.MusicaRelajacionPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'musica-estimulacion/:pacienteuid',
    loadChildren: () => import('./paciente/musica-estimulacion/musica-estimulacion.module').then( m => m.MusicaEstimulacionPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'memory-game/:pacienteuid',
    loadChildren: () => import('./paciente/memory-game/memory-game.module').then( m => m.MemoryGamePageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'mi-ficha/:pacienteuid',
    loadChildren: () => import('./paciente/mi-ficha/mi-ficha.module').then( m => m.MiFichaPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
