import { ContentChildren, Directive, ElementRef, EventEmitter, HostListener, Input, Output, QueryList } from'@angular/core';
import * as $ from 'jquery';
import { emulateTab } from 'emulate-tab';

@Directive({
  selector: '[appIonic]'
})
export class IonicDirective {
  @Input() onReturn: string;
  @Output() selectedTabClick = new EventEmitter();
  @ContentChildren(HTMLButtonElement) tabs: QueryList<HTMLButtonElement>;
  private el: ElementRef;
  constructor(private el1: ElementRef) {
      this.el = this.el1;
  }



  @HostListener('mousedown', ['$event'])
  onMousedown(event) {
    let element;
    switch (event.which) {
      case 1:
        event.preventDefault();
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const focused = <HTMLElement>document.querySelector(':focus');
        if(focused){
          focused.click();
        }
        //focused.click();
        console.log(focused);
      //        event.srcElement.ElementRef.pressed();
//          alert('Left Mouse button pressed.');

        break;
      case 2:
        event.preventDefault();
        emulateTab.backwards();
        console.log('mouse');
        break;
      case 3:
          event.preventDefault();
          emulateTab();
          console.log('mouse');
//        this.selectedTabClick.emit({tab});
        //        alert('Right Mouse button pressed.');
        break;
      default:
      //alert('You have a strange Mouse!');
    }
    console.log(event);
  }
}
